<template>
  <div style="
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      background: #142a40;
      color: #fff;
      overflow: hidden;
      width: 100%;
      height: 100%;
      padding: 10px;
    ">
    <controller v-if="isShow"></controller>
  </div>
</template>

<script>
import controller from '@/components/controller/controller2.0'

export default {
  components: {
    controller
  },
  data () {
    return {
      isShow: true,
      activeCode: {},
    }
  },
  methods: {
  }
};
</script>

